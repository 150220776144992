<template>
  <div class="account-proile-main-container">
    <div class="account-profile-container">
      <div class="account-profile-basic-details">
        <div class="account-profile-basic-details-section-1">
          <div>
            <img
              v-if="profileData.account.logo"
              src="{{profileData.account.logo}}"
              alt="Company Logo"
            />
            <a-avatar v-else shape="circle" :size="100">
              <template #icon><UserOutlined /></template>
            </a-avatar>
          </div>
        </div>
        <div class="header-right-section">
          <div class="account-profile-basic-details-section-2">
            <div class="account-profile-name-preference">
              <div class="account-name-status-client-id-company-type">
                <span class="account-profile-basic-details-name">
                  {{ profileData.account ? profileData.account.name : "-" }}
                </span>
                <div>
                  <span
                    class="account-profile-basic-details-status-tag"
                    :style="[
                      profileData.status === 'ACTIVE'
                        ? { background: 'rgba(111, 207, 151, 0.4)' }
                        : { background: 'rgba(76, 81, 85, 0.5)' },
                    ]"
                  >
                    {{ profileData.status }}
                  </span>
                </div>
                <span class="account-profile-client-details-header"
                  >Client ID :</span
                >
                <span
                  class="account-profile-client-details-value spacer-right"
                  >{{ profileData.account.companyId }}</span
                >
                <span class="account-profile-client-details-header"
                  >Company Type :</span
                >
                <span class="account-profile-client-details-value">{{
                  profileData.account.companyType
                }}</span>
              </div>
              <div style="margin: 0.5rem 0 1rem" class="account-preferences">
                <div>
                  <span class="account-profile-preferences-details"
                    >Sector :</span
                  >
                  <span
                    class="account-profile-preferences-value"
                    v-for="(sector, index) in sectors"
                    :key="index"
                  >
                    {{ sector }}
                  </span>
                  <span v-if="sectors.length === 0">-</span>
                </div>
                <div>
                  <span class="account-profile-preferences-details"
                    >Industry :</span
                  >
                  <span
                    class="account-profile-preferences-value"
                    v-for="(industry, index) in industries"
                    :key="index"
                  >
                    {{ industry }}
                  </span>
                  <span v-if="industries.length === 0">-</span>
                </div>
              </div>
            </div>
            <div class="account-profile-account-manager">
              <div class="title">Account Managers</div>
              <div
                class="value"
                v-for="(manager, index) in managers"
                :key="index"
              >
                {{ manager }}
              </div>
            </div>
          </div>

          <div class="account-profile-basic-details-section-3">
            <div class="country-last-meeting-vacancy-section">
              <div class="spacer-right">
                <span class="account-profile-bottom-details-2-header"
                  >Country</span
                >
                <span
                  v-if="profileData.address.country"
                  class="account-profile-bottom-details-2-value"
                >
                  {{ profileData.address.country }}
                </span>
                <span class="account-profile-bottom-details-2-value">-</span>
              </div>
              <div class="spacer-right">
                <span class="account-profile-bottom-details-2-header"
                  >Last Meeting</span
                >
                <span
                  v-if="profileData.lastMeetingAt"
                  class="account-profile-bottom-details-2-value"
                >
                  {{ displayLongDate(profileData.lastMeetingAt) }}
                </span>
                <span v-else class="account-profile-bottom-details-2-value">
                  -
                </span>
              </div>
              <div class="spacer-right">
                <span class="account-profile-bottom-details-2-header">
                  Last Vacancy
                </span>
                <span
                  v-if="profileData.lastVacancyAt"
                  class="account-profile-bottom-details-2-value"
                >
                  {{ displayLongDate(profileData.lastVacancyAt) }}
                </span>
                <span v-else class="account-profile-bottom-details-2-value">
                  -
                </span>
              </div>
            </div>
            <div class="header-website-creation-date">
              <div class="account-profile-account-website">
                <span class="title" style="margin-right: 10px">Website:</span>
                <span v-if="profileData.website" class="value">
                  {{ profileData.website }}
                </span>
                <span v-else class="account-profile-bottom-details-2-value">
                  -
                </span>
              </div>
              <div class="account-profile-account-creation-date">
                <span class="title" style="margin-right: 10px"
                  >Creation Date:</span
                >
                <span v-if="profileData.createdAt" class="value">
                  {{ displayLongDate(profileData.createdAt) }}
                </span>
                <span v-else class="account-profile-bottom-details-2-value">
                  -
                </span>
              </div>
            </div>
          </div>
        </div>
        <div>
          <a style="color: #000000" @click="router.push('/list-companies')">
            <CloseOutlined class="account-profile-cross-icon" />
          </a>
        </div>
      </div>
      <div class="account-profile-details-menu">
        <a-divider class="menu-top-divider" />
        <a-menu
          v-model:selectedKeys="selectedMenuKey"
          @click="handleMenuClick"
          mode="horizontal"
          class="account-details-menu"
        >
          <a-menu-item key="vacancies">
            <a href="#">Vacancies</a>
          </a-menu-item>
          <a-menu-item key="meetings">
            <a href="#meetings-table-section">Meetings</a>
          </a-menu-item>
          <a-menu-item key="teams">
            <a href="#account-profile-team-section">Teams</a>
          </a-menu-item>
          <a-menu-item key="clientPersons">
            <a href="#client-person-table-section">Client Persons</a>
          </a-menu-item>
          <a-menu-item key="documents">
            <a href="#documents-table-section">Documents</a>
          </a-menu-item>
          <a-menu-item key="otherDetails">
            <a href="#other-details-section">Other Details</a>
          </a-menu-item>
        </a-menu>
        <a-divider class="menu-bottom-divider" />
      </div>
      <div class="account-profile-vacancy-section">
        <div class="vacancy-section-time-tags">
          <div v-for="tag in tags" :key="tag">
            <a-checkable-tag
              :checked="selectedTags.indexOf(tag.id) > -1"
              @change="(checked) => handleChange(tag.id, checked)"
            >
              {{ tag.title }}
            </a-checkable-tag>
          </div>
        </div>
        <div class="vacancy-section-2">
          <div class="pie-chart-section">
            <div class="total-vacancy-count">
              <span class="count">{{ profileData.total }}</span
              ><span class="text">Vacancies</span>
            </div>
            <div class="vacancy-pie-chart"></div>
          </div>
          <div class="vacancy-categories">
            <div
              class="categories"
              v-for="category in vacancyCategories"
              :key="category.id"
            >
              <div class="color-box"></div>
              <div class="category-text">{{ category.name }}</div>
              <div class="category-count">{{ category.count }}</div>
            </div>
          </div>
          <a-divider type="vertical" class="vacancy-vertical-divider" />
          <div class="vacancy-cards">
            <a-card class="vacancy-card-1 vacancy-card">
              <span class="vacancy-card-number">{{ widgetData.closures }}</span>
              <span class="vacancy-card-text">Revenue</span>
            </a-card>

            <a-card class="vacancy-card-2 vacancy-card">
              <span class="vacancy-card-number">{{ widgetData.meetings }}</span>
              <span class="vacancy-card-text">Meetings</span>
            </a-card>

            <a-card class="vacancy-card-3 vacancy-card">
              <span class="vacancy-card-number" style="color: #4c5155">{{
                widgetData.revenue
              }}</span>
              <span class="vacancy-card-text">Closures</span>
            </a-card>
          </div>
        </div>
        <div class="vacancy-table-section">
          <a-table
            :columns="vacancyColumns"
            :data-source="vacancyData"
            :pagination="false"
            :rowKey="(record) => record.vacancyId"
          >
            <template #vacancy="{ text }">
              <div class="vacancy-table-text">{{ text.title }}</div>
              <a v-if="text.title !== '-'" class="vacancy-profile-btn">{{
                text.vacancyId
              }}</a>
              <a-button type="link" class="vacancy-taxonomy-btn">
                <BarChartOutlined
                  v-if="text.title !== '-'"
                  class="vacancy-taxonomy-icon"
                />
              </a-button>
            </template>
            <template #customTitle>
              <a-button type="link">
                <MenuOutlined style="color: #4c5155" />
              </a-button>
            </template>
          </a-table>
        </div>
        <div class="view-more-btn">
          <a-button
            v-if="vacancyData.length > 3"
            type="link"
            shape="round"
            class="borderless-btn"
            @click="viewMoreData('vacancy')"
          >
            <template #icon>
              <PlusCircleOutlined />
              View more
            </template>
          </a-button>
        </div>
      </div>
      <a-divider
        id="meetings-table-section"
        style="height: 1px; background-color: #000000"
      />
      <div class="meetings-table">
        <div class="table-title">
          <span v-if="meetingList.length < 10" style="font-size: 26px">0</span>
          <span class="count">{{ meetingList.length }}</span>
          <span class="table-name">Meetings</span>
        </div>
        <a-table
          :columns="meetingColumns"
          :data-source="meetingList"
          :pagination="false"
          :row-selection="meetingRowSelection"
          :rowKey="(index) => index"
        >
        </a-table>
      </div>
      <a-divider
        id="account-profile-team-section"
        style="height: 1px; background-color: #000000"
      />
      <div class="account-profile-subTeam-rmTagged-section">
        <div class="top-sub-section">
          <span class="number" v-if="subTeamData.length < 10">0</span>
          <span style="font-size: 26px">{{ subTeamData.length }}</span
          ><span class="text"> Sub Teams</span>
          <span class="number" v-if="subTeamData.length < 10">0</span>
          <span style="font-size: 26px">{{ rmTaggedData.length }}</span
          ><span class="text"> RM Tagged</span>
        </div>
        <div class="sub-tem-table-section">
          <a-table
            :columns="subTeamColumns"
            :data-source="subTeamData"
            :pagination="false"
            :rowKey="(index) => index"
            :row-selection="subTeamRowSelection"
          >
            <template #customTitle>
              <div class="custom-title">
                <div class="subTeam-table-custom-title-top-text">In 12M</div>
                <a-divider class="subTeam-table-custom-title-divider" />
                <div class="subTeam-table-custom-title-bottom-text-container">
                  <div>Vacancies Received</div>
                  <div style="margin: 0 5rem">Closures</div>
                  <div>Revenue</div>
                </div>
              </div>
            </template>
            <template #ragStatus="{ text }">
              <span :style="{ color: '#' + text.color }">{{ text.name }}</span>
            </template>
            <template #vacanciesReceived="{ text }">
              <div style="text-align: center">{{ text }}</div>
            </template>
            <template #closures="{ text }">
              <div style="text-align: center; margin-left: 20px">
                {{ text }}
              </div>
            </template>
            <template #revenue="{ text }">
              <div style="text-align: center">{{ text }}</div>
            </template>
          </a-table>
          <div class="view-more-btn">
            <a-button
              v-if="subTeamData.length > 3"
              type="link"
              shape="round"
              class="borderless-btn"
              @click="viewMoreData('subTeam')"
            >
              <template #icon>
                <PlusCircleOutlined />
                View more
              </template>
            </a-button>
          </div>
        </div>
        <div class="sub-tem-table-section">
          <a-table
            :columns="rmTaggedColumns"
            :data-source="rmTaggedData"
            :pagination="false"
            :rowKey="(index) => index"
            :row-selection="rmTaggedRowSelection"
          >
            <template #ragStatus="{ text }">
              <span :style="{ color: text.color }">{{ text.name }}</span>
            </template>
          </a-table>
          <div class="view-more-btn">
            <a-button
              v-if="rmTaggedData.length > 3"
              type="link"
              shape="round"
              class="borderless-btn"
              @click="viewMoreData('meeting')"
            >
              <template #icon>
                <PlusCircleOutlined />
                View more
              </template>
            </a-button>
          </div>
        </div>
      </div>
      <a-divider
        id="client-person-table-section"
        style="height: 1px; background-color: #000000"
      />
      <div class="client-person-table">
        <div class="table-title">
          <span v-if="clientPersonList.length < 10" style="font-size: 26px"
            >0</span
          >
          <span class="count">{{ clientPersonList.length }}</span>
          <span class="table-name">Client Person</span>
        </div>
        <a-table
          :columns="clientPersonColumns"
          :data-source="clientPersonList"
          :pagination="false"
          :row-selection="clientPersonRowSelection"
          :rowKey="(index) => index"
        >
        </a-table>
      </div>

      <a-divider
        id="documents-table-section"
        style="height: 1px; background-color: #000000"
      />
      <div class="documents-table">
        <div class="table-title">
          <span v-if="documentList.length < 10" style="font-size: 26px">0</span>
          <span class="count">{{ documentList.length }}</span>
          <span class="table-name">Documents</span>
        </div>
        <a-table
          :columns="documentColumn"
          :data-source="documentList"
          :pagination="false"
          :row-selection="documentRowSelection"
          :rowKey="(index) => index"
        >
          <template #fileSize="{ text }">
            <span v-if="text">{{ text }}</span>
          </template>
        </a-table>
      </div>
      <div class="terms-table">
        <div class="table-title">
          <span v-if="termsList.length < 10" style="font-size: 26px">0</span>
          <span class="count">{{ termsList.length }}</span>
          <span class="table-name">Terms</span>
        </div>
        <a-table
          :columns="termsColumns"
          :data-source="termsList"
          :pagination="false"
          :row-selection="termsRowSelection"
          :rowKey="(index) => index"
        >
        </a-table>
      </div>
      <div class="agreements" style="padding-bottom: 0">
        <div class="table-title">
          <span class="count">00</span>
          <span class="table-name">Agreements</span>
        </div>
      </div>

      <a-divider
        id="other-details-section"
        style="height: 1px; background-color: #000000"
      />

      <div class="other-details-section">
        <p>Other Details</p>
        <div class="account-profile-text-area">
          <a-textarea
            class="form-text-area"
            placeholder="Enter Account Profile"
            v-model:value="profileTextArea"
            :autoSize="{ minRows: 4, maxRows: 6 }"
          />
        </div>
        <div class="account-profile-additional-details">
          <div class="entity-list">
            <p class="title">Entity</p>
            <div class="additional-details-list">
              <span class="list key">Entity Name:</span>
              <div v-if="entityList.length !== 0">
                <p v-for="(entity, index) in entityList" :key="index">
                  <span class="list value" style="color: rgb(0, 0, 0)">
                    {{ entity === "" ? "-" : entity }}&nbsp;&nbsp;
                  </span>
                </p>
              </div>
              <div v-else>
                <span class="list value" style="color: rgb(0, 0, 0)">-</span>
              </div>
            </div>
          </div>
          <a-divider class="section-divider" style="width: 100%" />
          <div class="office-list">
            <p class="title">Office</p>
            <div
              v-for="(office, index) in officeList"
              :key="index"
              style="margin-bottom: 0.75rem"
            >
              <div
                class="additional-details-list"
                style="margin-bottom: 0.5rem"
              >
                <div
                  style="display: flex; flex-direction: row; flex-basis: 50%"
                >
                  <p class="list key">Office Name:</p>
                  <p>
                    <span class="list value">{{
                      office.officeName == "" || office.officeName == null
                        ? "-"
                        : office.officeName
                    }}</span>
                  </p>
                </div>
                <div
                  style="display: flex; flex-direction: row; flex-basis: 50%"
                >
                  <p class="list key">Office Type:</p>
                  <p>
                    <span class="value list">{{
                      office.officeType === "" ? "-" : office.officeType
                    }}</span>
                  </p>
                </div>
              </div>
              <div class="additional-details-list" style="margin-top: 0.5rem">
                <p class="key list">Office Address:</p>
                <p>
                  <span class="value list">{{
                    office.officeAddress === "" ? "-" : office.officeAddress
                  }}</span>
                </p>
              </div>
            </div>
          </div>
          <a-divider class="section-divider" style="width: 100%" />
          <div class="entity-list">
            <p class="title">Sub Team</p>
            <div class="additional-details-list">
              <span class="list key">Sub Team Name:</span>
              <div v-if="subTeamsList.length !== 0">
                <p v-for="(subteam, index) in subTeamsList" :key="index">
                  <span class="list value" style="color: rgb(0, 0, 0)">
                    {{ subteam === "" ? "-" : subteam }}&nbsp;&nbsp;
                  </span>
                </p>
              </div>
              <div v-else>
                <span class="list value" style="color: rgb(0, 0, 0)">-</span>
              </div>
            </div>
          </div>
          <a-divider class="section-divider" style="width: 100%" />
          <div class="billing-entity-list">
            <p class="title">Billing Entities</p>
            <div class="additional-details-list">
              <span class="list key">Billing Entity:</span>
              <div v-if="billingEntityList.length !== 0">
                <p v-for="(billing, index) in billingEntityList" :key="index">
                  <span class="list value" style="color: rgb(0, 0, 0)">
                    {{ billing === "" ? "-" : billing }}&nbsp;&nbsp;
                  </span>
                </p>
              </div>
              <div v-else>
                <span class="list value" style="color: rgb(0, 0, 0)">-</span>
              </div>
            </div>
          </div>
          <a-divider class="section-divider" style="width: 100%" />
          <div class="address-list">
            <p class="title">Address</p>
            <div class="additional-details-list">
              <span class="list key">Address:</span>
              <p v-if="profileData.address.street">
                <span class="list value" style="color: rgb(0, 0, 0)">
                  {{ profileData.address.street }}&nbsp;&nbsp;
                </span>
                <span class="list value" style="color: rgb(0, 0, 0)">
                  {{ profileData.address.city }}&nbsp; - &nbsp;
                </span>
                <span class="list value" style="color: rgb(0, 0, 0)">
                  {{ profileData.address.zipcode }}&nbsp;&nbsp;
                </span>
                <span class="list value" style="color: rgb(0, 0, 0)">
                  {{ profileData.address.state }}&nbsp;&nbsp;
                </span>
                <span class="list value" style="color: rgb(0, 0, 0)">
                  {{ profileData.address.country }}&nbsp;&nbsp;
                </span>
              </p>
              <p v-else>
                <span> - </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="right-section">
      <ActionBar />
    </div>
  </div>
</template>
<script>
const selectedMenuKey = ref(["vacancies"]);
var shiftWindow = function () {
  scrollBy(0, -250);
  console.log(screen);
};
if (selectedMenuKey.value == "teams") {
  console.log("teaammmmmmmmmm");
}
import { useRouter } from "vue-router";
import { reactive, ref, toRefs, onMounted } from "vue";
import { useRoute } from "vue-router";
import Services from "@/services/apis";
import dayjs from "dayjs";
import {
  PlusCircleOutlined,
  UserOutlined,
  CloseOutlined,
  BarChartOutlined,
  MenuOutlined,
} from "@ant-design/icons-vue";
import ActionBar from "./rightNav.vue";

let entityList = reactive([]);
let subTeamsList = reactive([]);
let officeList = reactive([]);
let billingEntityList = reactive([]);
let profileData = reactive({
  account: {
    id: "",
    companyId: "",
    companyType: "",
    name: "",
    logo: "",
  },
  clientPersons: {
    id: "",
    name: "",
    contactNumber: "",
    email: "",
  },
  website: "",
  address: {
    type: "",
    street: "",
    city: "",
    state: "",
    zipcode: "",
    country: "",
  },
  accountManagers: [
    {
      id: "",
      name: "",
      subTeam: {
        id: "",
        name: "",
      },
    },
  ],
  subTeams: [
    {
      id: "",
      name: "",
      rag: {
        id: "",
        name: "",
        color: "",
      },
      lastMandate: "",
      runningVacancies: 0,
      "12MVacancies": {
        total: 0,
        closures: 0,
        revenue: 0,
      },
    },
  ],
  industries: [
    {
      id: "",
      name: "",
    },
  ],
  sectors: [
    {
      id: "",
      name: "",
    },
  ],
  offices: [
    {
      id: "",
      name: "",
      address: {
        street: "",
        city: "",
        state: "",
        country: "",
        zip: "",
      },
      type: "",
    },
  ],
  entities: [
    {
      id: "",
      name: "",
    },
  ],
  createdBy: {
    id: "",
    name: "",
  },
  originatedBy: {
    id: "",
    name: "",
  },
  status: "",
  createdAt: "",
  lastMeetingAt: "",
  lastaccountAt: "",
});

export default {
  components: {
    UserOutlined,
    CloseOutlined,
    BarChartOutlined,
    MenuOutlined,
    PlusCircleOutlined,
    ActionBar,
  },

  setup() {
    const {
      params: { id },
    } = useRoute();
    let profileTextArea = ref("");
    let router = useRouter();
    let widgetData = reactive({
      closures: 0,
      meetings: 0,
      revenue: 0,
    });
    const handleMenuClick = (event) => {
      console.log("event", event, selectedMenuKey);
    };
    let currentPageNumVac;
    let currentPageNumMeeting;
    let currentPageNumSubTeam;
    if (location.hash) shiftWindow();
    window.addEventListener("hashchange", shiftWindow);
    const timeLineState = reactive({
      tags: [
        { id: "6M", title: "6M" },
        { id: "12M", title: "12M" },
        { id: "24M", title: "24M" },
        { id: "CY", title: "CY" },
        { id: "PY", title: "PY" },
        { id: "L2Y", title: "Last 2 Years" },
      ],
      selectedTags: ["6M"],
    });
    const viewMoreData = (value) => {
      if (value == "vacancy") {
        currentPageNumVac++;
        getAccountsVacancies(id, (range = "6M"));
      } else if (value == "subTeam") {
        currentPageNumSubTeam++;
        getAccountProfileData(listDataObj);
      } else if (value == "meetings") {
        currentPageNumMeeting++;
        getAccountsMeetings();
      }
    };
    // function to handle selected tags from vacancy timeline
    const handleChange = (tag, checked) => {
      const { selectedTags, tags } = timeLineState;
      const nextSelectedTags = checked
        ? tags.filter((t) => t.id === tag)
        : selectedTags.filter((t) => t.id !== tag);
      timeLineState.selectedTags = nextSelectedTags[0].id;
      getAccountsVacancies(id, tag);
    };
    let vacancyCategories = reactive([]);
    const vacancyColumns = [
      {
        title: "Vacancy",
        key: "vacancy",
        dataIndex: "vacancy",
        width: 175,
        slots: { customRender: "vacancy" },
      },
      {
        title: "# of Resumes",
        key: "resumes",
        dataIndex: "resumes",
        align: "center",
      },
      {
        title: "Vac. Type",
        key: "type",
        dataIndex: "type",
      },
      {
        title: "Salary",
        key: "salary",
        dataIndex: "salary",
      },
      {
        title: "Vac. Value",
        key: "value",
        dataIndex: "value",
      },
      {
        title: "Sub Team",
        key: "subTeams",
        dataIndex: "subTeams",
      },
      {
        colSpan: 1,
        width: 0,
        key: "manageColumns",
        slots: { title: "customTitle" },
      },
    ];
    const vacancyData = reactive([]);

    const subTeamColumns = [
      {
        title: "Sub Team",
        key: "subTeam",
        dataIndex: "subTeam",
        align: "center",
      },
      {
        title: "RAG Status",
        key: "ragStatus",
        dataIndex: "ragStatus",
        align: "center",
        slots: {
          customRender: "ragStatus",
        },
      },
      {
        title: "Last Mandate Received Date",
        key: "mandateDate",
        dataIndex: "mandateDate",
        align: "center",
      },
      {
        slots: {
          title: "customTitle",
          customRender: "vacanciesReceived",
        },
        key: "vacanciesReceived",
        dataIndex: "vacanciesReceived",
        colSpan: 3,
        align: "center",
      },
      {
        slots: {
          customRender: "closures",
        },
        title: "Closures",
        key: "closures",
        dataIndex: "closures",
        align: "center",
        colSpan: 0,
      },
      {
        slots: {
          customRender: "revenue",
        },
        title: "Revenue",
        key: "revenue",
        dataIndex: "revenue",
        align: "center",
        colSpan: 0,
      },
    ];
    let subTeamData = reactive([]);

    // Sub Team Row Selection
    let subTeamList = [];
    const subTeamRowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        subTeamList = [];
        for (let i = 0; i < selectedRows.length; i++) {
          subTeamList.push(selectedRows[i].name);
        }
      },
    };

    const rmTaggedColumns = [
      {
        title: "RM Tagged",
        key: "rmTagged",
        dataIndex: "rmTagged",
        align: "center",
      },
      {
        title: "RAG Status",
        key: "ragStatus",
        dataIndex: "ragStatus",
        align: "center",
        slots: {
          customRender: "ragStatus",
        },
      },
      {
        title: "Client Person",
        key: "clientPerson",
        dataIndex: "clientPerson",
        align: "center",
      },
      {
        title: "Team",
        key: "team",
        dataIndex: "team",
        align: "center",
      },
      {
        title: "Meeting in 12M",
        key: "meetingIn12",
        dataIndex: "meetingIn12",
        align: "center",
      },
      {
        title: "Last Meeting",
        key: "lastMeeting",
        dataIndex: "lastMeeting",
        align: "center",
      },
    ];

    const rmTaggedData = reactive([]);

    let rmTaggedSelection = [];
    const rmTaggedRowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        rmTaggedSelection = [];
        for (let i = 0; i < selectedRows.length; i++) {
          rmTaggedSelection.push(selectedRows[i].name);
        }
      },
    };

    const clientPersonColumns = [
      {
        key: "unit",
        dataIndex: "unit",
        title: "Unit",
        align: "center",
      },
      {
        key: "name",
        dataIndex: "name",
        title: "Name",
        align: "center",
      },
      {
        key: "designation",
        dataIndex: "designation",
        title: "Designation",
        align: "center",
      },
      {
        key: "mobile",
        dataIndex: "mobile",
        title: "Mobile Number",
        align: "center",
      },
      {
        key: "phone",
        dataIndex: "phone",
        title: "Phone Office",
        align: "center",
      },
      {
        key: "email",
        dataIndex: "email",
        title: "Email",
        align: "center",
      },
      {
        key: "dob",
        dataIndex: "dob",
        title: "DOB",
        align: "center",
      },
    ];

    let clientPersonList = reactive([]);

    let clientPersonSelection = [];
    const clientPersonRowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        clientPersonSelection = [];
        for (let i = 0; i < selectedRows.length; i++) {
          clientPersonSelection.push(selectedRows[i].name);
        }
      },
    };

    const meetingColumns = [
      {
        key: "clientPersons",
        dataIndex: "clientPersons",
        title: "Client Person",
        align: "center",
      },
      {
        key: "date",
        dataIndex: "date",
        title: "Date",
        align: "center",
      },
      {
        key: "department",
        dataIndex: "department",
        title: "Department",
        align: "center",
      },
      {
        key: "leadCics",
        dataIndex: "leadCics",
        title: "Lead CIC",
        align: "center",
      },
      {
        key: "attendedCics",
        dataIndex: "attendedCics",
        title: "Attended CIC",
        align: "center",
      },
      {
        key: "tracker_type",
        dataIndex: "tracker_type",
        title: "Tracker Type",
        align: "center",
      },
      {
        key: "team",
        dataIndex: "team",
        title: "Team",
        align: "center",
      },
      {
        key: "subTeam",
        dataIndex: "subTeam",
        title: "Sub Team",
        align: "center",
      },
    ];

    let meetingList = reactive([]);

    let meetingSelection = [];
    const meetingRowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        meetingSelection = [];
        for (let i = 0; i < selectedRows.length; i++) {
          meetingSelection.push(selectedRows[i].name);
        }
      },
    };

    const documentColumn = [
      {
        title: "File Name",
        dataIndex: "fileName",
        key: "fileName",
        align: "center",
      },
      {
        title: "File Size",
        dataIndex: "fileSize",
        slots: { customRender: "fileSize" },
        key: "fileSize",
        align: "center",
      },
      {
        title: "Document Type",
        dataIndex: "docType",
        key: "docType",
        align: "center",
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
        align: "center",
      },
      {
        title: "Date",
        dataIndex: "date",
        key: "date",
        align: "center",
      },
    ];

    let documentList = reactive([]);

    let documentSelection = [];
    const documentRowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        documentSelection = [];
        for (let i = 0; i < selectedRows.length; i++) {
          documentSelection.push(selectedRows[i].name);
        }
      },
    };

    const termsColumns = [
      {
        key: "level",
        dataIndex: "level",
        title: "Level",
        align: "center",
      },
      {
        key: "isFixed",
        dataIndex: "isFixed",
        title: "Is Fixed",
        textalign: "center",
      },
      {
        key: "fee",
        dataIndex: "fee",
        title: "Fixed Fee",
        textalign: "center",
      },
      {
        key: "percentage",
        dataIndex: "percentage",
        title: "Percentage",
        textalign: "center",
      },
      {
        key: "feeLimit",
        dataIndex: "feeLimit",
        title: "Fee Limit",
        textalign: "center",
      },
    ];

    let termsList = reactive([]);

    let termsSelection = [];
    const termsRowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        termsSelection = [];
        for (let i = 0; i < selectedRows.length; i++) {
          termsSelection.push(selectedRows[i].name);
        }
      },
    };
    let managers = reactive([]);
    let sectors = reactive([]);
    let industries = reactive([]);

    const getAccountProfileData = (id) => {
      Services.getAccountProfile(id)
        .then((response) => {
          const { data } = response;
          Object.assign(profileData, data);
          let accountManagers = profileData.accountManagers.map((manager) => {
            return manager.name;
          });
          Object.assign(managers, accountManagers);

          let sectorList = profileData.sectors.map((sector) => {
            return sector.name;
          });
          Object.assign(sectors, sectorList);

          let industryList = profileData.industries.map((sector) => {
            return sector.name ? sector.name : "-";
          });
          Object.assign(industries, industryList);
          let subTeam = profileData.subTeams.map((subteam) => {
            return subteam.name;
          });
          Object.assign(subTeamsList, subTeam);
          let entities = profileData.entities.map((entity) => {
            return entity.name;
          });
          Object.assign(entityList, entities);

          let offices;
          if (profileData.offices.length > 0) {
            offices = profileData.offices.map((office) => {
              let address;
              if (
                office.address.street ||
                office.address.city ||
                office.address.state ||
                office.address.zipcode ||
                office.address.country
              ) {
                address =
                  office.address.street +
                  " " +
                  office.address.city +
                  " " +
                  office.address.zipcode +
                  " " +
                  office.address.state +
                  " " +
                  office.address.country;
              } else {
                address = "-";
              }
              object = {
                officeNmae: office.name ? office.name : "-",
                officeType: office.type ? office.type : "-",
                officeAddress: address ? address : "-",
              };
              return object;
            });
          } else {
            offices = [
              {
                officeName: "-",
                officeType: "-",
                officeAddress: "-",
              },
            ];
          }
          Object.assign(officeList, offices);

          let tempdata = [];
          if (profileData.subTeams) {
            profileData.subTeams.map((team) => {
              tempdata.push({
                id: team.id ? team.id : tempdata.length + 1,
                subTeam: team.name ? team.name : "-",
                ragStatus: {
                  name: team.rag.name ? team.rag.name : "-",
                  color: team.rag.color ? team.rag.color : "#000000",
                },
                mandateDate: team.lastMandate
                  ? displayLongDate(team.lastMandate)
                  : "-",
                vacanciesReceived: team["12MVacancies"].total
                  ? team["12MVacancies"].total
                  : 0,
                closures: team["12MVacancies"].closures
                  ? team["12MVacancies"].closures
                  : 0,
                revenue: team["12MVacancies"].revenue
                  ? team["12MVacancies"].revenue
                  : 0,
              });
            });
          }
          Object.assign(subTeamData, tempdata);

          let rms;
          let object;
          if (profileData.rms) {
            rms = profileData.rms.map((rm) => {
              let object = {
                rmTagged: rm.rm.name ? rm.rm.name : "-",
                ragStatus: rm.rag
                  ? {
                      name: rm.rag.name ? rm.rag.name : "-",
                      color: rm.rag.color ? rm.rag.color : "#000000",
                    }
                  : { name: "-", color: "#000000" },
                clientPerson: rm.clientPerson
                  ? rm.clientPerson.name
                    ? rm.clientPerson.name
                    : "-"
                  : "-",
                team: rm.team ? (rm.team ? rm.teamm.name : "-") : "-",
                lastMeeting: rm.lastMeeting
                  ? displayLongDate(rm.lastMeeting)
                  : "-",
                meetingIn12: rm["12MVacancies"] ? rm["12MVacancies"] : 0,
              };
              return object;
            });
          }
          Object.assign(rmTaggedData, rms);

          let cpList;
          if (profileData.clientPersons) {
            cpList = profileData.clientPersons.map((person) => {
              let object = {
                unit: person.unit
                  ? person.unit.name
                    ? person.unit.name
                    : "-"
                  : "-",
                name: person.name ? person.name : "-",
                designation: person.designation ? person.designation : "-",
                mobile: person.contactNumber ? person.contactNumber : "-",
                phone: person.phone ? person.phone : "-",
                email: person.email ? person.email : "-",
                dob: person.dob ? displayLongDate(person.dob) : "-",
              };
              return object;
            });
          }
          Object.assign(clientPersonList, cpList);
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const getAccountsDocuments = (id) => {
      let tempData = [];
      Services.getAccountDocumnets(id)
        .then((response) => {
          if (response.status === 200) {
            let { data } = response;
            data &&
              data[0].count &&
              data[0].documents.map((document) => {
                tempData.push({
                  fileName: document.name ? document.name : "-",
                  fileSize: document.size ? document.size + " kb" : "-",
                  docType: document.type ? document.type : "-",
                  date: document.createdAt
                    ? displayDate(document.createdAt)
                    : "-",
                  description: document.description
                    ? document.description
                    : "-",
                });
              });
            Object.assign(documentList, tempData);
          }
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const getAccountsMeetings = (id) => {
      let tempData = [];
      Services.getAccountMeetings(id)
        .then((response) => {
          if (response.status === 200) {
            let { data } = response;
            data &&
              data[0].count &&
              data[0].meetings.map((meeting) => {
                tempData.push({
                  subTeam:
                    meeting.subTeams.length > 0
                      ? meeting.subTeams.map((subTeam) =>
                          subTeam.name ? subTeam.name : "-"
                        )
                      : "-",
                  team:
                    meeting.teams.length > 0
                      ? meeting.teams.map((team) =>
                          team.name ? team.name : "-"
                        )
                      : "-",
                  clientPersons:
                    meeting.clientPersons.length > 0
                      ? meeting.clientPersons.map((cp) =>
                          cp.name ? cp.name : "-"
                        )
                      : "-",
                  attendedCics:
                    meeting.attendedCics.length > 0
                      ? meeting.attendedCics.map((cp) =>
                          cp.name ? cp.name : "-"
                        )
                      : "-",
                  leadCics:
                    meeting.leadCics.length > 0
                      ? meeting.leadCics.map((cp) =>
                          meeting.leadCics.length < 1 ? cp.name : "-"
                        )
                      : "-",
                  department: meeting.department.name
                    ? meeting.department.name
                    : "-",
                  date: meeting.date ? displayLongDate(meeting.date) : "-",
                  tracker_type: meeting.type ? meeting.type : "-",
                });
              });
            Object.assign(meetingList, tempData);
            console.log("meetingList", meetingList);
          }
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const getAccountsTerms = (id) => {
      let tempData = [];
      Services.getAccountTerms(id)
        .then((response) => {
          if (response.status === 200) {
            let { data } = response;
            console.log("response", data.length);
            if (data.length) {
              data &&
                data.map((term) => {
                  tempData.push({
                    level: term.name ? term.name : "-",
                    isFixed: term.isFixed ? term.isFixed : "-",
                    fee: term.fee ? term.fee : "-",
                    percentage: term.percentage ? term.percentage : "-",
                    feeLimit: term.feeLimit ? term.feeLimit : "-",
                  });
                });
            }
            Object.assign(termsList, tempData);
          }
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const getAccountsVacancies = (id, range = "6M") => {
      let payload = {
        id: id,
        range: range,
        pageNo: currentPageNumVac,
      };
      let vacCategories = [
        {
          id: "",
          title: "Running",
          key: "Running",
          count: 0,
        },
        {
          id: "",
          title: "Placed By Vito",
          key: "Placed by Vito",
          count: 0,
        },
        {
          id: "",
          title: "Placed By Consultant",
          key: "Placed by Consultant",
          count: 0,
        },
        {
          id: "",
          title: "On Hold - Delay Hiring",
          key: "On Hold - Delayed Hiring",
          count: 0,
        },
        {
          id: "",
          title: "On Hold - No Response",
          key: "On Hold - No response",
          count: 0,
        },
        {
          id: "",
          title: "Placed Internally",
          key: "Placed Internally",
          count: 0,
        },
        {
          id: "",
          title: "Dead",
          key: "Dead",
          count: 0,
        },
      ];
      let tempData;
      Services.getAccountVacancies(payload)
        .then((response) => {
          if (response.status === 200) {
            let { data } = response;
            let statuses = data.statistics.statuses;
            tempData = {
              closures: data.statistics.closures ? data.statistics.closures : 0,
              meetings: data.statistics.meetings ? data.statistics.meetings : 0,
              revenue: data.statistics.revenue ? data.statistics.revenue : 0,
            };
            Object.assign(widgetData, tempData);
            vacCategories.map((category) => {
              statuses.map((status) => {
                (category.id = status.statusId),
                  (category.count = status.count);
              });
            });
            console.log("statuses :>> ", statuses);
            profileData["total"] = data.statistics.total;
            Object.assign(vacancyCategories, statuses);
            let vacancies = [
              {
                vacancy: {
                  title: "-",
                  vacancyId: "1",
                },
                type: "-",
                resumes: 0,
                salary: 0,
                value: 0,
                subTeams: "-",
              },
            ];
            let tempData = [];
            data &&
              data.vacancies.length &&
              data.vacancies.map((vacancy) => {
                tempData.push({
                  vacancy: {
                    title: vacancy.vacancy.title ? vacancy.vacancy.title : "-",
                    vacancyId: vacancy.vacancy.vacancyId
                      ? vacancy.vacancy.vacancyId
                      : "-",
                  },
                  type: vacancy.vacancy.type ? vacancy.vacancy.type : "-",
                  resumes: vacancy.resumes ? vacancy.resumes : 0,
                  salary: vacancy.salary ? parseInt(vacancy.vacancy.type) : 0,
                  value: vacancy.value ? vacancy.value : "-",
                  subTeams:
                    vacancy.subTeams.length > 0
                      ? vacancy.subTeams.map((subTeam) => {
                          subTeam.name;
                        })
                      : "-",
                });
              });
            if (data.vacancies.length) {
              Object.assign(vacancyData, tempData);
            }
          }
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    function displayLongDate(value) {
      return dayjs(value).format("DD/MM/YYYY");
    }

    onMounted(() => {
      getAccountProfileData(id);
      getAccountsDocuments(id);
      getAccountsMeetings(id);
      getAccountsTerms(id);
      getAccountsVacancies(id);
    });

    return {
      vacancyCategories,
      ...toRefs(timeLineState),
      handleChange,
      router,
      selectedMenuKey,
      handleMenuClick,
      widgetData,
      vacancyColumns,
      viewMoreData,
      vacancyData,
      subTeamColumns,
      subTeamData,
      subTeamRowSelection,
      rmTaggedColumns,
      rmTaggedData,
      rmTaggedRowSelection,
      profileTextArea,
      entityList,
      subTeamsList,
      officeList,
      billingEntityList,
      clientPersonColumns,
      clientPersonList,
      clientPersonRowSelection,
      meetingColumns,
      meetingList,
      meetingRowSelection,
      documentColumn,
      documentList,
      documentRowSelection,
      termsColumns,
      termsList,
      termsRowSelection,
      displayLongDate,
      profileData,
      managers,
      sectors,
      industries,
    };
  },
};
</script>
<style lang="scss">
@import "../styles/accountProfile.scss";
</style>
